<template>
    <div>
        <Divider dashed><span class="divider-text">合同收付汇总</span></Divider>
         <Row class="p-b-10">
                <i-col span="8" class="title">合同总额 </i-col>
                <i-col span="16" class="money">
                    {{formatMoney(contractSummary.amount?contractSummary.amount:0)}}
                </i-col>
            </Row>
            <Row class="p-b-10">
                <i-col span="8"  class="title">累计收款额 </i-col>
                <i-col span="16" class="money">
                    {{formatMoney(contractSummary.receivedAmount?contractSummary.receivedAmount:0)}}
                </i-col>
            </Row>
            <Row class="p-b-10" >
                <i-col span="8" class="title">逾期未收款额 </i-col>
                <i-col span="16" class="money">
                    {{formatMoney(contractSummary.overdueNotReceiveAmount?contractSummary.overdueNotReceiveAmount:0)}}
                </i-col>
            </Row>
    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
export default {
  methods: {
    formatMoney (value) {
      return toMoney(value)
    }
  },
  computed: {
    contractSummary () {
      return this.$store.state.payment ? this.$store.state.payment.contractSummary : null
    }
  }
}
</script>
